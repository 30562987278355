import { sortByProperty } from '@/helpers/sort';
import {
  IfacPositionHelpers,
} from '@ifac/ui';

export const gridDefaultColDef = {
  resizable: true,
  sortable: true,
};

export function gridColumns(ProfileLink) {
  return [
    {
      headerName: 'Year',
      sortable: false,
      sortIndex: 0,
      sort: 'desc',
      autoHeight: true,
      cellRenderer: (params) => {
        const { positions } = params.data;

        positions.sort((a, b) => b.triennium.number - a.triennium.number);

        return positions
          .map((p) => `<div>${p.triennium.to}</div>`)
          .join('');
      },
      valueGetter: (params) => params.data,
      comparator: (valueA, valueB) => sortByProperty(valueA.positions[0].triennium, valueB.positions[0].triennium, 'number'),
    },
    {
      headerName: 'Full Name',
      flex: 1,
      sort: 'asc',
      sortIndex: 1,
      sortable: false,
      valueGetter: (params) => params.data,
      cellRenderer: ProfileLink,
      comparator: (valueA, valueB) => sortByProperty(valueA, valueB, 'surname'),
    },
    {
      headerName: 'Paper',
      flex: 2,
      sortable: false,
      autoHeight: true,
      cellRenderer: (params) => {
        const { positions } = params.data;

        positions.sort((a, b) => b.triennium.number - a.triennium.number);

        return positions
          .map((p) => `
          <div style="white-space: normal;word-break: break-word;line-height: normal;padding:10px 0">
            ${IfacPositionHelpers.parseNotes(p.notes)}
          </div>
          `)
          .join('<hr style="margin: 0">');
      },
    },
  ];
}
